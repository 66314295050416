import {
  Typography,
  Box,
  Card,
  Container,
  CardContent,
  CardActions,
} from "@mui/material";
import * as React from "react";
import theme from "../theme";
import PropTypes from "prop-types";
import AppButton from "../components/global/elements/AppButton";
import { HandleLogin } from "../services/auth";
import { navigate } from "gatsby";
import DarkLogo from "src/assets/logos/globhe_logo/Globhe_symbol_2022_black.png";
import WhiteLogo from "src/assets/images/globhe_logo/Globhe_symbol_2022_white.png";
import { userRoles } from "../lib/constants";

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: { xs: "flex-start", md: "center" },
    alignItems: "flex-start",
    height: { xs: "auto", md: "96vh" },
    padding: { xs: "40px 20px", md: "0" },
  },
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: "32px 0px 0px",
    width: { xs: "100%", md: "380px", lg: "483px" },
    height: { xs: "60vh", md: "420px", lg: "471px" },
  },
  greenCard: {
    backgroundColor: theme.palette.primary.main,
  },
  purpleCard: {
    backgroundColor: theme.palette.secondary.main,
  },
  whiteText: {
    color: theme.palette.grey.white,
    "&.MuiButtonBase-root": {
      color: theme.palette.grey.white,
      "&:hover": {
        color: theme.palette.black.darkSeaBlack,
      },
    },
  },
  upperText: {
    display: { xs: "none", md: "block" },
    textAlign: "left",
    // padding: { xs: 0, md: "0px 32px" },
  },
  buttonContainer: {
    display: "flex",
    flexDirection: { xs: "column-reverse", md: "row" },
    alignItems: { xs: "center", md: "flex-start" },
    justifyContent: "space-between",
    padding: { xs: "20px", md: 0 },
    gap: "16px",
    width: "100%",
    "& a": {
      width: { xs: "100%", md: "unset" },
    },
  },
  cardContent: {
    height: "88%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignitems: "flex-start",
  },
  subContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    gap: "16px",
  },
  cardBox: {
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    alignItems: "center",
    gap: "1rem",
  },
  h5: {
    color: theme.palette.grey.warmGrey,
    paddingBottom: { xs: "10px", md: 0 },
  },
  greyText: {
    marginBottom: "20px",
  },
  fullWidth: {
    width: { xs: "100%", md: "unset" },
  },
  logoTItle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingBottom: { xs: "10px", md: 0 },
  },
  logo: {
    width: "30px",
    height: "30px",
    marginRight: "20px",
  },
};

const CardComponent = ({
  topText,
  text,
  signUp,
  signIn,
  color,
  buttonText,
  user,
}) => {
  const clickHandler = async () => {
    try {
      let navRoute = await HandleLogin();
      if (!navRoute) {
        navigate(signUp);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const isGreen = color === "green";

  return (
    <Card
      sx={{
        ...styles.card,
        ...(isGreen ? styles.greenCard : styles.purpleCard),
      }}
    >
      <CardContent sx={styles.cardContent}>
        <Box
          component="img"
          src={user === userRoles.client ? DarkLogo : WhiteLogo}
          sx={styles.logo}
        />
        <Typography
          sx={{
            ...styles.upperText,
            ...(!isGreen && styles.whiteText),
          }}
          variant="subtitle1Med"
        >
          {topText}
        </Typography>
        <Box sx={styles.subContainer}>
          <Typography variant="h6" sx={!isGreen && styles.whiteText}>
            Create account
          </Typography>
          <Typography sx={!isGreen ? styles.whiteText : {}} variant="h2">
            {text}
          </Typography>
        </Box>
      </CardContent>
      <CardActions sx={styles.buttonContainer}>
        <AppButton
          look="ghost"
          noIcon
          label={buttonText}
          onClick={() => clickHandler()}
          addtionalStyle={{
            ...styles.fullWidth,
            ...(!isGreen ? styles.whiteText : {}),
          }}
        />
        <AppButton
          look="black"
          label="Already a user? Login"
          url={signIn}
          internalLink
          addtionalStyle={styles.fullWidth}
        />
      </CardActions>
    </Card>
  );
};

CardComponent.propTypes = {
  topText: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  signUp: PropTypes.string.isRequired,
  signIn: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
};

const IndexPage = () => {
  return (
    <Container maxWidth="lg">
      <Box sx={styles.root}>
        <Box sx={styles.greyText}>
          <Box sx={styles.logoTItle}>
            {/* <img src={DarkLogo} alt="Logo" style={styles.logo} /> */}
            <Typography variant="h3">Register with GLOBHE</Typography>
          </Box>

          <Typography variant="h5" sx={styles.h5}>
            We&apos;re focused on one thing. To provide the best possible
            understanding of our planet.
          </Typography>
          <Typography variant="h5" sx={styles.h5}>
            We do that by making drone data easily accessible through this
            platform.
          </Typography>
        </Box>
        <Box sx={styles.cardBox}>
          <CardComponent
            topText="Order and manage your drone data in our platform. You can subscribe to drone data, create one time orders or access our vast library of archive data. All in just a few clicks."
            text="I want to order data"
            signUp="/crowddroning/client/sign-up/"
            signIn="/crowddroning/client/sign-in/"
            color="green"
            buttonText="Create a client account"
            user={userRoles.client}
          />

          <CardComponent
            topText="Sign up and join the Crowddroningmovement.
            Become a part of a vast network of drone operators collectively changing the world."
            text="I’m a drone operator"
            signUp="/crowddroning/pilot/sign-up"
            signIn="/crowddroning/pilot/sign-in"
            color="purple"
            buttonText="Create an operator account"
            user={userRoles.pilot}
          />
        </Box>
      </Box>
    </Container>
  );
};

export default IndexPage;
